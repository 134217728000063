<template>
  <div>
    <v-form ref="form">
      <div class="px-14">
        <v-row>
          <v-col>
            <p class="form-label py-0 mb-2">Form Name</p>
            <v-text-field
              placeholder=""
              outlined
              label=""
              hide-details="auto"
              background-color="white"
              v-model="form.form_title"
              :rules="[
                (v) => !!v || 'Form name is required',
                (v) =>
                  (v && v.length <= 300) ||
                  'Form name must be less than 300 characters',
              ]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div
          id="switch__sect"
          class="d-flex flex-wrap flex-md-nowrap align-center"
        >
          <v-switch
            dense
            :disabled="disablePayment"
            hide-details="auto"
            v-model="form.is_payment"
            @change="revertBack"
            style="font-size: 12px"
          >
            <template v-slot:label>
              <span class="text-body-2">Take Payment</span>
            </template>
          </v-switch>
          <!-- <v-switch
            dense
            hide-details="auto"
            class="ml-md-4"
            @change="allowSignature"
            v-model="form.has_signature"
          >
            <template v-slot:label>
              <span class="text-body-2">Take Signature</span>
            </template>
          </v-switch> -->
          <v-switch
            dense
            hide-details="auto"
            class="ml-md-4"
            v-model="form.allow_files"
          >
            <template v-slot:label>
              <span class="text-body-2">Allow Files</span>
            </template>
          </v-switch>
        </div>
        <div class="mt-4">
          <!-- <p class="form-label py-0 mb-2">Form Description</p> -->
          <v-textarea
            outlined
            label=""
            background-color="white"
            hide-details="auto"
            placeholder="form description"
            v-model="form.form_description"
            :rules="[
              (v) => !!v || 'Form description is required',
              (v) =>
                (v && v.length <= 600) ||
                'Form description must be less than 600 characters',
            ]"
          >
          </v-textarea>
        </div>
        <br />

        <div class="d-flex align-center justify-center">
          <div class="pr-2">
            <p class="form-label py-0 mb-2">Form Type</p>
            <v-select
              outlined
              label=""
              :disabled="isEdit"
              background-color="white"
              hide-details="auto"
              :items="formType"
              item-text="text"
              item-value="value"
              v-model="form.form_type"
            >
            </v-select>
          </div>
          <div class="pl-2">
            <p class="form-label py-0 mb-2">Assignee</p>
            <v-select
              outlined
              label=""
              :disabled="isEdit"
              background-color="white"
              hide-details="auto"
              :items="entitytypeType"
              item-text="text"
              item-value="value"
              v-model="form.entitytype"
            >
            </v-select>
          </div>
        </div>
      </div>
      <DialogActionBtns
        class="pr-0"
        @clickedCancel="$emit('cancel')"
        @click="changeTab"
        :btnText="
          form.form_type === 'api' || form.is_payment
            ? 'next'
            : isEdit
            ? 'update'
            : 'create'
        "
        :loading="loading"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogActionBtns from "../../../components/dialogActionBtns.vue";
export default {
  name: "createFormFields",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      disablePayment: false,

      form: {
        form_title: "",
        form_type: "",
        entitytype: "",
        field_names: undefined,
        form_description: "",
        form_fields: undefined,
        is_private: undefined,
        is_payment: false,
        // has_signature: false,
        allow_files: false,
      },

      formType: [
        { text: "Standard", value: "standard" },
        { text: "Commerce", value: "api" },
      ],
      entitytypeType: [
        { text: "Customer", value: "customer" },
        { text: "Vendor", value: "vendor" },
        { text: "Staff", value: "staff" },
      ],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.form)) {
          this.form = {
            ...val,
          };
        }
      },
      deep: true,
      immediate: true,
    },

    form: {
      handler(val) {
        if (val.form_type === "api") {
          this.form.is_payment = true;
          // this.form.has_signature = false;
          this.form.allow_files = false;
          this.disablePayment = true;
        } else {
          this.disablePayment = false;
        }

        // if (val.is_payment) {
        //   this.form.has_signature = false;
        // }

        // console.log("form ===>", JSON.stringify(val, null, 2));
        this.$emit("input", this.form);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    closeFormDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    //check and change the store value of is_payment switch

    //check and change the store value of add_signature switch
    // allowSignature() {
    //   if (this.form.has_signature == true) {
    //     this.form.is_payment = false;
    //   }
    // },

    //function to change the tab
    changeTab() {
      if (this.$refs.form.validate()) {
        this.dispatchAction();
        this.$emit("next", "details");
      }
    },

    // show api form tab

    //create form function
    createForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store.dispatch(
          "formBuilder/saveFormDetailsToStore",
          this.formDetails
        );
        localStorage.setItem("formDetails", JSON.stringify(this.formDetails));
        console.log(JSON.stringify(this.formDetails, null, 2));

        this.loading = false;
        this.closeFormDialog();
        // window.open(routeData.href, "_blank");
      } else {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Form name and Form Description is required",
          timeout: 3000,
        });
        this.loading = false;
      }
    },
    dispatchAction() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("formDetails", JSON.stringify(this.formDetails));
        this.$store.dispatch(
          "formBuilder/saveFormDetailsToStore",
          this.formDetails
        );
      } else return;
    },

    revertBack(e) {
      console.log(e);
      if (this.form.form_type === "api" && !e) {
        this.payment = true;
      }
    },
  },
  components: { DialogActionBtns },
};
</script>

<style scoped>
.form-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(25, 40, 61, 0.8);
}

.theme--light.v-input--switch .v-input--switch__thumb {
  color: #8f96a1 !important;
}

.v-text-field--outlined >>> fieldset {
  top: 0px !important;
}

.v-text-field--outlined legend {
  line-height: 0px !important;
  padding: 0 !important;
}

.v-application--is-ltr .v-text-field--outlined >>> legend {
  text-align: left !important;
  display: none !important;
}
</style>
