<template>
  <div class="px-4 py-2">
    <v-dialog v-model="dialog" max-width="530">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-card-title
          style="background: #ffffff; border-radius: 8px 8px 0px 0px"
        >
          <span
            style="
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #19283d;
            "
            >Company Onboarding</span
          >
          <v-spacer></v-spacer>
          <v-icon
            tag="button"
            @click="dialog = false"
            class="text-bolder"
            color="#596A73"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <div class="px-8 d-flex" style="background: #f3eee8">
          <v-tabs v-model="tab" background-color="#f3eee8" slider-size="4">
            <v-tab
              class="mt-2"
              v-for="item in items"
              :key="item"
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 800;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
              "
              >{{ item }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="tab" style="background: #f8f7f4">
          <v-tab-item>
            <v-card style="background-color: #f8f7f4" flat>
              <div class="my-5 mx-8">
                <p class="intro">
                  To begin collecting sales and auto-generating invoices for
                  your business, please complete or correct the details about
                  your business in the fields below
                </p>
              </div>
              <!-- company form starts here -->
              <v-form ref="form">
                <div class="mb-6">
                  <div class="field__row">
                    <v-text-field
                      v-model="company.company_name"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Company Name"
                      :rules="rules.company_name"
                    >
                    </v-text-field>
                  </div>
                  <div class="field__row">
                    <v-text-field
                      v-model="company.registration_number"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="CAC Reg number"
                      :rules="rules.registration_number"
                    >
                    </v-text-field>
                  </div>

                  <div class="field__row">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="company.registration_date"
                          :rules="rules.registration_date"
                          background-color="#ffffff"
                          outlined
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                          placeholder="CAC Reg date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="company.registration_date"
                        @input="menu3 = false"
                        no-title
                        scrollable
                        :max="getCurrentDate()"
                      ></v-date-picker>
                    </v-menu>
                  </div>

                  <div class="field__row">
                    <v-text-field
                      v-model="company.streetAddress"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Company Street Address"
                      :rules="rules.street_address"
                    >
                    </v-text-field>
                  </div>

                  <div class="field__row">
                    <v-select
                      v-model="company.businessType"
                      :items="companyTypeArray"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Business Type"
                      :rules="rules.business_type"
                    >
                    </v-select>

                    <v-select
                      class="text-truncate"
                      v-model="company.industry"
                      :items="industryTypeArray"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Industry"
                      :rules="rules.industry"
                    >
                    </v-select>
                  </div>

                  <div class="field__row">
                    <v-select
                      :items="accountingSoftwareArray"
                      v-model="company.accountSoftware"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Name of your accounting software"
                      :rules="rules.account_software"
                    >
                    </v-select>
                  </div>

                  <div class="field__row">
                    <template>
                      <v-card
                        @click="handleFileImport"
                        @drop.prevent="onDroppedFiles($event)"
                        @dragover.prevent="dragging = true"
                        @dragenter.prevent="dragging = true"
                        @dragleave.prevent="dragging = false"
                        width="515"
                        height="126"
                        class="mx-auto justify-center d-flex"
                        style=""
                        :style="{
                          border: dragging
                            ? '1px dashed #424f95'
                            : '1px dashed rgba(127, 145, 155, 0.551929)',
                        }"
                        flat
                      >
                        <span
                          v-if="isInitial"
                          class="uploadInfo d-flex mx-auto my-auto"
                          >{{ placeholder }}
                        </span>
                        <span
                          v-if="isSaving"
                          class="uploadInfo d-flex mx-auto my-auto"
                          >{{ cac.name }}
                          <v-icon
                            @click="removeFile($event, cac)"
                            small
                            tag="button"
                            color="red"
                            class="mx-4"
                          >
                            mdi-close</v-icon
                          >
                        </span>
                      </v-card>
                    </template>
                    <template>
                      <!-- <input ref="uploader" class="d-none" type="file" @change="onFileSelected" /> -->
                      <v-file-input
                        ref="uploader"
                        class="d-none"
                        accept="application/pdf,image/jpeg,image/jpg,image/png"
                        v-model="cac"
                        @change="onFileSelected"
                        :rules="rules.cac"
                      ></v-file-input>
                    </template>
                  </div>
                </div>
                <v-card-actions class="justify-end px-8 pb-8">
                  <v-btn
                    color="primary"
                    :loading="moveToNext"
                    @click="switchTabs('next')"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '121'"
                    height="45"
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span class="text-capitalize pl-3" style="color: #ffffff"
                      >Next</span
                    >
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-tab-item>

          <!-- tab content for leadership -->
          <v-tab-item>
            <div class="my-5 mx-8">
              <p class="intro">
                A company director, whose name appearson company registration
                document or authorised to approve bank payments
              </p>
            </div>
            <v-card style="background-color: #f8f7f4" flat>
              <v-form ref="form">
                <div class="">
                  <div class="field__row">
                    <v-text-field
                      v-model="company.leadership.firstName"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="First Name"
                      :rules="rules.first_name"
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="company.leadership.lastName"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Last Name"
                      :rules="rules.last_name"
                    >
                    </v-text-field>
                  </div>
                  <div class="field__row">
                    <v-text-field
                      v-model="company.leadership.email"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Email"
                      :rules="rules.email"
                    >
                    </v-text-field>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="company.leadership.dob"
                          :rules="rules.DOB"
                          background-color="#ffffff"
                          outlined
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                          placeholder="Date of Birth"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="company.leadership.dob"
                        @input="menu = false"
                        no-title
                        scrollable
                        :max="getCurrentDate()"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <v-row class="px-8 mb-2">
                    <v-col>
                      <v-text-field
                        hint="e.g. MD/CEO, Director, etc"
                        v-model="company.leadership.executivePosition"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Executive Position"
                        :rules="rules.executive_position"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="company.leadership.percentage"
                        type="number"
                        min="0"
                        max="100"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Percentage"
                        :rules="rules.percentage"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="px-8 mb-2">
                    <v-col>
                      <v-text-field
                        v-model="company.leadership.city"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="City"
                        :rules="rules.city"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="company.leadership.state"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="State"
                        :rules="rules.state"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div class="field__row">
                    <v-text-field
                      v-model="company.leadership.phone"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Phone number"
                      :rules="rules.phone"
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="company.leadership.bvn"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="BVN"
                      :rules="rules.bvn"
                    >
                    </v-text-field>
                  </div>
                  <div class="field__row">
                    <div class="select__wrapper">
                      <v-select
                        v-model="company.leadership.selectedIdType"
                        item-text="text"
                        item-value="value"
                        :items="idTypeArray"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Select ID type to upload"
                        :rules="rules.id_type"
                      >
                      </v-select>
                    </div>

                    <v-text-field
                      v-model="company.leadership.ID_number"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Enter Your ID number"
                      :rules="rules.id_number"
                    >
                    </v-text-field>
                  </div>
                </div>
                <v-card-actions class="justify-end px-8 pb-8">
                  <v-btn
                    color="primary"
                    :loading="updateKyc"
                    @click.prevent="saveKycData"
                    type="submit"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '121'"
                    height="45"
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span class="text-capitalize pl-3" style="color: #ffffff"
                      >Submit</span
                    >
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "company",
  components: {},
  data() {
    return {
      menu: false,
      menu3: false,
      moveToNext: false,
      updateKyc: false,
      dragging: false,
      dialog: false,
      // fileName: null,
      cac: null,
      placeholder: "Upload Company Registration Document. CAC Certificate",
      placeholderId: "Please upload a copy of selected ID",
      company: {
        registration_number: "",
        company_name: "",
        accountSoftware: "",
        streetAddress: "",
        businessType: "",
        industry: "",
        registration_document: [],
        registration_date: "",
        leadership: {
          firstName: "",
          lastName: "",
          executivePosition: "",
          percentage: "",
          bvn: "",
          email: "",
          phone: "",
          ID_number: "",
          selectedIdType: "",
          dob: "",
          city: "",
          state: "",
        },
      },
      rules: {
        first_name: [(v) => !!v || "First name is required"],
        company_name: [(v) => !!v || "Company name is required"],
        last_name: [(v) => !!v || "Last name is required"],
        executive_position: [(v) => !!v || "Executive position is required"],
        registration_date: [(v) => !!v || "Registration date is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) => !!v || "Phone number is required",
          (v) =>
            (v && v.length <= 15) ||
            "Phone number must be less than 15 characters",
        ],
        bvn: [
          (v) => !!v || "BVN is required",
          (v) => (v && v.length <= 11) || "BVN must be less than 11 characters",
        ],
        id_type: [(v) => !!v || "ID type is required"],
        DOB: [(v) => !!v || "DOB is required"],
        state: [(v) => !!v || "State is required"],
        city: [(v) => !!v || "City is required"],
        id_number: [(v) => !!v || "ID number is required"],
        cac: [(v) => !!v || "CAC is required"],
        registration_number: [
          (v) => !!v || "CAC Registration number is required",
        ],
        percentage: [this.validatePercentage],
      },

      isInitial: true,
      isSaving: false,
      currentStatus: null,
      tab: null,
      companyTypeArray: [
        "Limited Company",
        "Incorporated Trustees",
        "Business",
      ],
      industryTypeArray: [
        "agricultural",
        "ecommerce",
        "education",
        "financial",
        "gaming",
        "health",
        "hospitality",
        "legal",
        "logistics",
        "manufacturing",
        "NGO",
        "others",
        "Retail",
        "Wholesale",
        "Restaurants",
        "Hospitals",
        "Construction",
        "Insurance",
        "Unions",
        "RealEstate",
        "FreelanceProfessional",
        "OtherProfessionalServices",
        "OnlineRetailer",
        "OtherEducationServices",
      ],
      idTypeArray: [
        {
          text: "International Passport",
          value: "passport",
        },
        {
          text: "Driver's License",
          value: "drivers",
        },
        {
          text: "NIN",
          value: "nin",
        },
        {
          text: "Voters Card",
          value: "pvc",
        },
      ],

      items: ["Company", "Leadership"],
      accountingSoftwareArray: [
        "QuickBooks Desktop",
        "QuickBooks Online",
        "Sage",
        "MS Dynamics",
        "FreshBooks",
        "Wave",
        "Zoho",
      ],
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    // make sure the % value entered is between 0-100 and also required
    validatePercentage(value) {
      if (!value) {
        return "This field is required";
      }
      if (value < 1 || value > 100) {
        return "Please enter a value between 1 and 100";
      }
      return true;
    },

    handleFileImport() {
      // Trigger click on the FileInput
      this.$refs.uploader.$refs.input.click();
    },
    handleIdPick() {
      // Trigger click on the FileInput
      this.$refs.file.click();
    },

    //fxn to trigger input event in cac document
    onFileSelected(file) {
      this.cac = file;
      this.isInitial = false;
      this.isSaving = true;
    },

    //fxn for drag and drop
    onDroppedFiles(e) {
      this.dragging = false;
      this.cac = e;
      this.isInitial = false;
      this.isSaving = true;
    },
    // reset form to initial state

    removeFile(e) {
      e.preventDefault;
      this.isInitial = true;
      this.isSaving = false;
    },
    switchTabs(direction) {
      //switch case to check and activate the next tab
      switch (direction) {
        case "next":
          if (this.tab <= this.items.length - 1) {
            this.tab++;
            return;
          }
          if (this.tab == this.items.length) {
            this.saveKycData();
          }

          break;

        default:
          break;
      }
    },

    // set max date for leadership date of birth
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    async uploadCAC() {
      //initialize the formData
      if (this.cac) {
        const formData = new FormData();
        // add the data needed for uploading the cac doc...
        formData.append("files", this.cac);
        formData.append("ref", "kyc.company");
        formData.append("refId", this.organId);
        formData.append("field", "registration_doc");

        console.log(formData);

        try {
          await this.$store
            .dispatch("organizations/uploadCacDoc", formData)
            .then(
              this.showToast({
                sclass: "success",
                show: true,
                message: "Upload CAC document successfully..",
                timeout: 3000,
              })
            );
        } catch (error) {
          console.log(error);
          if (error) {
            this.showToast({
              sclass: "error",
              show: true,
              message: "CAC document upload was unsuccessful..",
              timeout: 3000,
            });
          }
        }
      }
    },

    //submit form fields alone... without the attached files
    async saveKycData() {
      if (this.$refs.form.validate()) {
        // send organization info to the server as an update request....
        this.updateKyc = true;

        const payload = {
          accounting_software: this.company.accountSoftware,
          office: [{ address: this.company.streetAddress }],
          company: {
            industry: this.company.industry,
            company_name: this.company.company_name,
            business_type: this.company.businessType,
            registration_number: this.company.registration_number,
            registration_date: this.company.registration_date,
          },

          leadership: [
            {
              first_name: this.company.leadership.firstName,
              last_name: this.company.leadership.lastName,
              position: this.company.leadership.executivePosition,
              email: this.company.leadership.email,
              phone: this.company.leadership.phone,
              identity: this.company.leadership.selectedIdType.value,
              identity_no: this.company.leadership.ID_number,
              bvn: this.company.leadership.bvn,
              percentage: this.percentageDecimal.toString(),
              city: this.company.leadership.city,
              state: this.company.leadership.state,
              dob: this.company.leadership.dob,
            },
          ],
        };

        // console.log(JSON.stringify(payload, null, 2))

        // axios request starts from here..
        try {
          //axios req to send organ. info and leadership...
          await this.$store
            .dispatch("organizations/updateOrganization", payload)
            .then((res) => {
              if (res.status === 200) {
                this.showToast({
                  sclass: "success",
                  show: true,
                  message: "Updated KYC successfully..",
                  timeout: 3000,
                });
              }
            });

          //calling axios post request to upload cac doc and id card..
          this.uploadCAC();
          // this.UploadIdCard();
        } catch (error) {
          console.log(error);
          if (error) {
            this.showToast({
              sclass: "error",
              show: true,
              message: error.msg,
              timeout: 3000,
            });
          }
        } finally {
          this.updateKyc = false;
          this.dialog = false;
        }
      }
    },

    // //get the organization info from the server
    async getOrganization() {
      try {
        const data = await this.$store.dispatch(
          "organizations/getOrganizationById",
          this.organId
        );

        // console.log(data)

        this.company.accountSoftware = data.accounting_software || "";
        this.company.streetAddress = data.office[0].address || "";
        this.company.businessType = data.company.business_type || "";
        this.company.company_name = data.company.company_name || "";
        this.company.industry = data.company.industry || "";
        this.company.registration_document[0] =
          data.company.registration_doc || "";
        this.company.leadership.firstName = data.leadership.first_name || "";
        this.company.leadership.lastName = data.leadership.last_name || "";
        this.company.leadership.executivePosition =
          data.leadership.position || "";
        this.company.leadership.email = data.leadership.email || "";
        this.company.leadership.phone = data.leadership.phone || "";
        this.company.leadership.ID_number = data.leadership.identity_no || "";
        this.company.leadership.selectedIdType =
          data.leadership.identity || "nin";
        this.company.leadership.bvn = data.leadership.bvn || "";
        this.company.leadership.dob = data.leadership.dob || "";
        this.company.leadership.state = data.leadership.state || "";
        this.company.leadership.city = data.leadership.city || "";
        this.company.registration_number =
          data.company.registration_number || "";
        this.company.registration_date = data.company.registration_date || "";
        this.company.leadership.percentage =
          parseFloat(data.leadership.percentage) * 100 || "";

        // console.log(this.company)
      } catch (error) {
        console.log(error);
      }
    },

    show() {
      this.getOrganization();
      this.dialog = true;
    },
  },
  computed: {
    //

    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      organId: "auth/organizationId",
      //company: "organizations/organObj",
    }),

    percentageDecimal() {
      if (this.company.leadership.percentage) {
        return (parseFloat(this.company.leadership.percentage) / 100).toFixed(
          2
        );
      }
      return null;
    },
  },
  mounted() {
    // this.getOrganization();
  },
};
</script>

<style scoped>
.field__row {
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
  column-gap: 20px;
  margin-bottom: 20px;
}

.intro-container {
  width: 426px;
}

.intro {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(25, 40, 61, 0.8);
}

.drag-drop-card {
  margin-top: 30px;
  margin-bottom: 30px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.uploadInfo {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #7f919b;
}

.v-file-input .v-file-input >>> placeholder {
  font-size: 9px !important;
}

.select__wrapper {
  width: 67%;
}

@media screen and (min-width: 480px) {
  .select__wrapper {
    width: 62%;
  }
}

@media screen and (min-width: 768px) {
  .select__wrapper {
    width: 50%;
  }
}
</style>
